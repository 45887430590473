import {useEffect, useState} from 'react';
import {Button, SelectBox} from 'devextreme-react';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest, getBanxeData, getBanxeTariff} from 'services/async';
import {vars} from 'utils/variables';

const {APP_CODES, NOTIFY} = vars;

const GetBanxeData = ({closePopup, rowData}) => {
	console.log('rowData');
	console.log(rowData);
	const [response, setResponse] = useState(null);
	
	useEffect(() => {
		getBanxeData({
			merchantId: rowData.MerchantID,
		}).then((response) => {
			setResponse(response);
		}).catch((error) => {
			notifyApp(error);
		});
	}, []);
	
	const sendBanxeClientDocuments = async () => {
		const request = await apiRequest({
			operation: 'Banxe/SendBanxeClientDocuments',
			data: {
				Params: {
					MerchantID: rowData.MerchantID,
				}
			}
		});
		
		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			notifyApp('Операция успешна', NOTIFY.SUCCESS);
		} else {
			notifyApp(request.data.ResponseText);
		}
	}
	
	return response ? (
		<div className={'create-legal-client-wrapper'}>
			<div className={'create-legal-client-select'}>
				<div className="dx-field">
					<div className="dx-field-label">Banxe ID</div>
					<div className="dx-field-value">{response.ClientExternalID}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Статус</div>
					<div className="dx-field-value">{response.ExternalStatus}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Причина смены статуса</div>
					<div className="dx-field-value">{response.ExternalStatusName}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Экспорт документов</div>
					<div className="dx-field-value field-value-flex">
						<div>{response.IsExportDocumentComplete === 1 ? 'Yes' : 'No'}</div>
						<Button
							disabled={rowData.UserTypeID !== 1}
							className={'custom-action-button'}
							text={'Повторить отправку'}
							icon='mdi mdi-cloud-upload'
							onClick={sendBanxeClientDocuments}
							stylingMode={'outlined'}
						/>
					</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Ошибка по клиенту</div>
					<div className="dx-field-value">{response.ErrorText}</div>
				</div>
			</div>
		</div>
	) : null;
}

export default GetBanxeData;