import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow, Item,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring, Toolbar,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {exportClientTransactionPayments, getPaymentList} from 'services/requestConsts';
import {
	toggleContextMenu,
	getAppliedFilters,
	getFromLocalStorage,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import Button from 'devextreme-react/button';
import AdminPopup from 'components/popup/admin-popup';
import {AcceptDeclineReplay, Info} from './transactionsPopupFields';
import classNames from 'classnames/bind';
import {ButtonGroup} from 'devextreme-react/button-group';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {withRouter} from 'react-router-dom';
import {vars} from 'utils/variables';

import './transactions.scss';

const {TRANSACTION, STATE_STORING_KEYS: {CLIENTS: {TRANSACTIONS_PAGE}}} = vars;

const getTitle = (actionType, {ID}) => {
	const titles = {
		info: `Детальная информация о платеже ID ${ID}`,
		accept: `Подтвердить платеж: ID ${ID}`,
		confirm: `Подтвердить платеж с отменой в шлюзе: ID ${ID}`,
		decline: `Отменить платеж: ID ${ID}`,
		replay: `Проверить статус платежа: ID ${ID}`,
	};
	
	return titles[actionType];
};

const getVisibleButtons = (row, isVisibleButton, handleGridColumnButton) => {
	const {PaymentStatusID} = row.data;
	
	const gridButtons = [
		{
			hint: 'Edit',
			icon: 'mdi mdi-replay',
			visible: () => {
				return isVisibleButton(
					TRANSACTION.RIGHTS.REPLAY,
					PaymentStatusID,
					[TRANSACTION.RECHECK_PAYMENT_STATUS_ID, TRANSACTION.RECHECK2_PAYMENT_STATUS_ID],
				);
			},
			onClick: () => {
				handleGridColumnButton('replay', AcceptDeclineReplay, row.data);
			},
		},
		{
			hint: 'Info',
			icon: 'info',
			visible: true,
			onClick: () => {
				handleGridColumnButton('info', Info, row.data);
			},
		},
		{
			hint: 'Action',
			icon: 'mdi mdi-dots-vertical',
			visible: () => {
				const ids = [TRANSACTION.RIGHTS.ACCEPT ,TRANSACTION.RIGHTS.ACCEPT_GATEWAY, TRANSACTION.RIGHTS.DECLINE];
				return isVisibleButton(ids, PaymentStatusID);
			},
			isContextMenuBtn: true,
		},
	];
	
	return gridButtons.filter((item) => {
		if (typeof item.visible === 'function') {
			return item.visible();
		}
		
		return item.visible;
	});
}

const contextInnerButtons = [
	{
		action: 'accept',
		hint: 'Accept',
		icon: 'mdi mdi-check-circle',
		right: TRANSACTION.RIGHTS.ACCEPT,
	},
	{
		action: 'confirm',
		hint: 'Mark as confirmed',
		icon: 'mdi mdi-check-circle-outline',
		right: TRANSACTION.RIGHTS.ACCEPT_GATEWAY,
	},
	{
		action: 'decline',
		hint: 'Decline',
		icon: 'mdi mdi-close-circle',
		right: TRANSACTION.RIGHTS.DECLINE,
	},
]

const CustomButtonGroupCell = ({row, isVisibleButton, handleGridColumnButton}) => {
	const {data: {PaymentStatusID}} = row;
	
	const handleClick = async (args) => {
		const {element, event, itemData} = args;
	
		if (itemData.isContextMenuBtn) {
			const ctxMenu = document.getElementById('ctx-menu');
			const rect = element.getBoundingClientRect();
			
			contextInnerButtons.forEach((btn) => {
				const btnElement = document.getElementById(btn.action);
				
				if (isVisibleButton(btn.right, PaymentStatusID)) {
					btnElement.classList.remove('invisible');
				} else {
					btnElement.classList.add('invisible');
				}
				
				btnElement.onclick = () => {
					toggleContextMenu();
					handleGridColumnButton(btn.action, AcceptDeclineReplay, row.data);
				}
			});
			
			ctxMenu.style.top = (rect.bottom - 86) + 'px';
			ctxMenu.style.left = (rect.right - ctxMenu.offsetWidth) + 'px';
			
			toggleContextMenu();
		}
	};
	
	return (
		<div className={'button-group-wrapper'}>
			<ButtonGroup
				items={getVisibleButtons(row, isVisibleButton, handleGridColumnButton)}
				keyExpr='action'
				stylingMode='outlined'
				onItemClick={handleClick}
			/>
		</div>
	);
};

class Transactions extends React.Component {
	constructor(props) {
		super(props);
		console.log('Trans');
		console.log(props);
		const merchantFilterID = getFromSessionStorage('filter', 'merchantFilterID');
		const accountFilterID = getFromSessionStorage('filter', 'accountFilterID');
		const merchantSenderAccountFilter = getFromSessionStorage('filter', 'merchantSenderAccountFilter');
		this.gridRef = React.createRef();
		
		this.state = {
			payments: [],
			showFilter: !!merchantFilterID,
			rowData: {
				ID: null,
				Amount: null,
				ForeignCurrencyID: null,
			},
			popupFields: null,
			popupActionType: null,
			merchantFilterID: merchantFilterID,
			accountFilterID: accountFilterID,
			merchantSenderAccountFilter: merchantSenderAccountFilter,
			isShowExportDatePopup: false,
		};
		
		this.filterOptions = {
			BoPaymentTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('BoPaymentTypeID'),
				options: {
					object: 'BoPaymentType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			AccountPaymentSystemTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('AccountPaymentSystemTypeName'),
				options: {
					object: 'PaymentSystemType',
					displayName: 'Name',
					keyName: 'Name',
					value: undefined,
					onValueChanged: () => {
					},
					additionalFilters: {ID: [0, 11, 12, 21]},
				},
			},
			PaymentSystemTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentSystemTypeID'),
				options: {
					object: 'PaymentSystemType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			PaymentStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentStatusID'),
				options: {
					object: 'PaymentStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				},
			},
			ReceiveCurrency: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('ForeignCurrencyID'),
				options: {
					object: 'Currency',
					displayName: (data) => {
						return data && this.displayCurrency(data.Code, data.Symbol);
					},
					keyName: 'ID',
					value: undefined,
					additionalCols: ['Code', 'Symbol'],
					onValueChanged: () => {
					},
				},
			},
			AccountCurrency: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('AccountCurrencyID'),
				options: {
					object: 'Currency',
					displayName: (data) => {
						return data && this.displayCurrency(data.Code, data.Symbol);
					},
					keyName: 'ID',
					value: undefined,
					additionalCols: ['Code', 'Symbol'],
					onValueChanged: () => {
					},
				},
			},
		};
	}
	
	componentDidMount() {
		this.getPaymentList();
	}
	
	getPaymentList = async () => {
		const paramObj = getPaymentList();
		const payments = getDataSource(paramObj);
		window.sessionStorage.removeItem('filter');
		this.setState({
			payments,
		});
	};
	
	dateColumn(props) {
		return (
			<div>
				<span>
					{props.text}
				</span>
				<br/>
				<span className={'utc'}>
					{props.data.UTC}
				</span>
			</div>
		);
	}
	
	colorRedClass(id) {
		return classNames({
			'special-payment-type-id': id === TRANSACTION.SPECIAL_BO_PAYMENT_TYPE_ID,
		});
	}
	
	paymentTypeColumn = ({data: {BoPaymentTypeID}, value}) => {
		return <div className={this.colorRedClass(BoPaymentTypeID)}>{value}</div>;
	};
	
	currencyColumn = ({data: {AccountCurrencyCode, AccountCurrencySymbol, BoPaymentTypeID}}) => {
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{this.displayCurrency(AccountCurrencyCode, AccountCurrencySymbol)}
			</span>
		);
	};
	
	amountColumn = (props) => {
		const {data: {BoPaymentTypeID}, text} = props;
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};
	
	feeColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};
	
	totalAmountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};
	
	
	displayCurrency(code, symbol) {
		return `${code} ${symbol}`
	}
	
	toReceiveCurrencyColumn = ({data: {ForeignCurrencyCode, ForeignCurrencySymbol, BoPaymentTypeID}}) => {
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{this.displayCurrency(ForeignCurrencyCode, ForeignCurrencySymbol)}
			</span>
		);
	};
	
	toReceiveAmountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};
	
	toReceiveCurrencyExchangeColumn = ({data}) => {
		const {AccountCurrencySymbol, ForeignCurrencySymbol, CurrencyRate, BoPaymentTypeID} = data;
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{`${AccountCurrencySymbol} 1 = ${ForeignCurrencySymbol} ${CurrencyRate.toFixed(2)}`}
			</span>
		);
	};
	
	statusColumn({value, data}) {
		const {STATUS_COLOR} = TRANSACTION;
		let statusClass;
		switch (data.PaymentStatusID) {
			case 4:
				statusClass = STATUS_COLOR.GREEN;
				break;
			case 5:
			case 6:
				statusClass = STATUS_COLOR.RED;
				break;
			case 1:
				statusClass = STATUS_COLOR.BLACK;
				break;
			default:
				statusClass = STATUS_COLOR.OTHER;
		}
		return <span className={`status-column ${statusClass}`}>{value}</span>;
	}
	
	handleGridColumnButton = (actionType, component, rowData) => {
		this.setState({
			popupFields: component,
			rowData: rowData,
			popupActionType: actionType,
		});
	};
	
	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};
	
	isVisibleButton = (right, paymentStatusID, expectedStatus = TRANSACTION.SPECIAL_PAYMENT_STATUS_ID) => {
		const { rights } = this.props;
		
		const expectedStatuses = Array.isArray(expectedStatus) ? expectedStatus : [expectedStatus];
		const isStatusMatch = expectedStatuses.includes(paymentStatusID);
		
		if (Array.isArray(right)) {
			return isStatusMatch && rights.some((item) => right.includes(item));
		} else {
			return isStatusMatch && rights.includes(right);
		}
	}
	
	/**
	 *
	 * @param {object} e
	 * @param {string} e.dataField
	 * @param {HTMLElement} e.editorElement
	 * @param {object} e.editorOptions
	 * @param {boolean} e.cancel
	 * @param {any} e.value
	 * @param {'dataRow' | 'filterRow' | 'headerRow' | 'searchPanel'} e.parentType
	 */
	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;
		
		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged,
				},
			}));
		}
	};
	
	saveTransactionsSettings = (settings) => {
		saveGridSettings(settings, TRANSACTIONS_PAGE);
	};
	
	loadTransactionSettings = () => {
		const {accountFilterID, merchantFilterID, merchantSenderAccountFilter} = this.state;
		return loadGridSettings(accountFilterID ?
				{AccountID: accountFilterID} :
				merchantFilterID ?
					{AccountMerchantID: merchantFilterID} :
					merchantSenderAccountFilter ?
						{MerchantSenderAccount: merchantSenderAccountFilter} :
						null,
			TRANSACTIONS_PAGE,
		);
	};
	
	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}
	
	inOrderCurrencyCaptions = () => {
		return (
			<div className={'in-order-currency'}>
				<p>Сумма</p>
				<p>Комиссия</p>
				<p>Итого</p>
				<p>Курс</p>
			</div>
		);
	};
	
	inAccountCurrency = (obj) => {
		const {
			AccountCurrencyCode, Amount, FeeAmount, ClearAmount,
		} = obj.data;
		
		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${ClearAmount} ${AccountCurrencyCode}`}</p>
				<p>{`${FeeAmount} ${AccountCurrencyCode}`}</p>
				<p>{`${Amount} ${AccountCurrencyCode}`}</p>
			</div>
		);
	};
	
	inPaymentCurrency = (obj) => {
		const {
			ForeignCurrencyCode, ForeignAmount, ForeignFeeAmount, ForeignClearAmount, CurrencyRate, IsPayGateAmountDiff,
		} = obj.data;
		
		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${ForeignClearAmount} ${ForeignCurrencyCode}`}</p>
				<p>{`${ForeignFeeAmount} ${ForeignCurrencyCode}`}</p>
				<p className={classNames({
					'special-payment-type-id': IsPayGateAmountDiff === 1,
				})}>{`${ForeignAmount} ${ForeignCurrencyCode}`}</p>
				<p>{CurrencyRate}</p>
			</div>
		);
	}
	
	toggleFilter = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		});
	}
	
	clearFilters = () => {
		if (this.gridRef.current) {
			this.gridRef.current.instance.clearFilter()
		}
	}
	
	exportGrid = () => {
		const {isShowExportDatePopup} = this.state;
		this.setState({isShowExportDatePopup: !isShowExportDatePopup});
	}
	
	render() {
		const {payments, showFilter, popupFields, popupActionType, rowData, isShowExportDatePopup} = this.state;
		const PopupFields = popupFields;
		const popupWidth = popupActionType === 'info' ? 1200 : 700;
		const popupHeight = popupActionType === 'info' ? '80%' : 'auto';
		
		return (
			<div className={'page-component-wrapper-custom'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!popupFields}
					maxWidth={popupWidth}
					height={popupHeight}
					title={getTitle(popupActionType, rowData)}
				>
					{popupFields ? (
						<PopupFields
							updatePaymentsList={this.getPaymentList}
							popupActionType={popupActionType}
							closePopup={this.closePopup}
							rowData={rowData}
						/>
					) : null}
				</AdminPopup>
				<div className={'grid-wrapper'}>
					<DataGrid
						id={'grid-acc-statement'}
						ref={this.gridRef}
						alignment={'center'}
						dataSource={payments}
						hoverStateEnabled={true}
						showBorders={false}
						focusedRowEnabled={false}
						columnHidingEnabled={true}
						onEditorPreparing={this.onEditorPreparing}
						height={'100%'}
						onContentReady={({component, element}) => {
							getAppliedFilters(component, element);
						}}
						onRowDblClick={({data}) => {
							this.handleGridColumnButton('info', Info, data);
						}}
					>
						<Toolbar>
							<Item location={'before'}>
								<div className={'recent-operation'}>
							<span className={'recent-operation-text'}>
								{TRANSACTION.TEXT.FILTERS}
							</span>
								</div>
							</Item>
							<Item location={'before'}>
								<Button icon={'filter'} onClick={this.toggleFilter}/>
							</Item>
							<Item location={'before'}>
								<Button icon={'clearsquare'} onClick={this.clearFilters}/>
							</Item>
							<Item location={'after'}>
								<Button
									icon={'xlsxfile'}
									onClick={this.exportGrid}
								/>
							</Item>
						</Toolbar>
						<RemoteOperations
							paging
							filtering
						/>
						<StateStoring
							enabled={true}
							type="custom"
							customLoad={this.loadTransactionSettings}
							customSave={this.saveTransactionsSettings}
							savingTimeout={100}
						/>
						<Paging enabled defaultPageSize={50}/>
						<FilterRow visible={showFilter}/>
						<FilterPanel visible={true}/>
						<Scrolling
							mode={'infinite'}
							showScrollbar="onHover"
						/>
						<Column
							dataField={'MerchantSenderAccount'}
							visible={false}
						/>
						<Column
							dataField={'AccountID'}
							visible={false}
						/>
						<Column
							dataField={'InsDate'}
							hidingPriority={9}
							dataType={'datetime'}
							caption={'Дата'}
							format={'dd.MM.yyyy, HH:mm'}
							cellRender={this.dateColumn}
							width={130}
						/>
						<Column
							dataField={'ID'}
							caption={'ID транзакции'}
							hidingPriority={8}
							width={100}
						/>
						<Column
							dataField={'AccountMerchantID'}
							caption={'ID мерчанта'}
							hidingPriority={7}
							width={100}
						/>
						<Column
							dataField={'BoPaymentTypeName'}
							caption={'Тип операции'}
							hidingPriority={6}
							filterOperations={this.filterOptions['BoPaymentTypeName'].filterOperations}
							calculateFilterExpression={this.filterOptions['BoPaymentTypeName'].calculateFilterExpression}
							//cellRender={this.paymentTypeColumn}
							width={200}
						/>
						<Column
							dataField={'AccountPaymentSystemTypeName'}
							caption={'ПС счета'}
							filterOperations={this.filterOptions['AccountPaymentSystemTypeName'].filterOperations}
							calculateFilterExpression={this.filterOptions['AccountPaymentSystemTypeName'].calculateFilterExpression}
							hidingPriority={6}
							width={180}
						/>
						<Column
							cellRender={this.inOrderCurrencyCaptions}
							hidingPriority={5}
							width={128}
						/>
						<Column
							alignment={'center'}
							caption={'В валюте счета'}
							cellRender={this.inAccountCurrency}
							hidingPriority={4}
						/>
						<Column
							alignment={'center'}
							caption={'В валюте оплаты'}
							cellRender={this.inPaymentCurrency}
							hidingPriority={3}
						/>
						<Column
							dataField={'PaymentSystemTypeName'}
							caption={'ПС оплаты'}
							filterOperations={this.filterOptions['PaymentSystemTypeName'].filterOperations}
							calculateFilterExpression={this.filterOptions['PaymentSystemTypeName'].calculateFilterExpression}
							hidingPriority={2}
							width={180}
						/>
						<Column
							dataField={'PaymentStatusName'}
							caption={'Статус'}
							hidingPriority={1}
							filterOperations={this.filterOptions['PaymentStatusName'].filterOperations}
							calculateFilterExpression={this.filterOptions['PaymentStatusName'].calculateFilterExpression}
							cellRender={this.statusColumn}
							width={150}
						/>
						<Column
							type={'buttons'}
							allowReordering={false}
							alignment={'center'}
							cellRender={(row) => {
								return <CustomButtonGroupCell
									row={row}
									isVisibleButton={this.isVisibleButton}
									handleGridColumnButton={this.handleGridColumnButton}
								/>
							}}
						/>
					</DataGrid>
				</div>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'TransactionsExport'}
					getGridParams={getPaymentList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
					exportCaptions={exportClientTransactionPayments}
				/>
				
				<div id={'ctx-menu'}>
					<div className={'ctx-menu'}>
						{contextInnerButtons.map((ctxMenuItem) => {
							return (
								<div key={ctxMenuItem.action} id={ctxMenuItem.action} className={'column-operation-button'}>
									<i className={ctxMenuItem.icon}></i>
									<span>
										{ctxMenuItem.hint}
									</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Transactions);
